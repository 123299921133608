.user {
	padding-bottom: 8rem;
	.title {
		margin: 30px 0;
	}
	.toggleMenuUser {
		display: none;
		width: 29.14px;
		height: 40px;
		padding: 14px 3px 0 0;
		color: @loe-gray;
		position: absolute;
		top: 4px;
		left: 10px;
		.transition(all 0.50s);
		@media (max-width: @screen-sm-max) {
			display: block;
		}
		span, span:after, span:before { height: 2px; background-color: @loe-gray; transition: all 0.3s; backface-visibility: hidden; }
		span { position: relative; display: block; width: 90%; }
		span:after, span:before { content: ""; position: absolute; left: 0; top: -8px; width: 120%; }
		span:after{ top: 8px; }
		&.on span { background-color: transparent; }
		&.on span:before { .transform(rotate(45deg) translate(6px, 5px)); }
		&.on span:after { .transform(rotate(-45deg) translate(6px, -6px)); }
	}
	.userMenu {
		.transition(all 0.20s);
		@media (max-width: @screen-sm-max) {
			left: -100%;
			position: absolute;
			top: 0;
		}
		&.on {
			left: 0;
		}
	}
	.userContent {
		.transition(all 0.20s);
		&.on {
			margin-left: 50%;
			@media (max-width: @screen-xs-max) {
				margin-left: 100%;
			}
		}
	}
	h4 {
		background-color: @loe-gray-light;
		border-bottom: 2px solid @loe-gray;
		padding: 10px 20px;
		margin: 0 0 20px 0;
		position: relative;
		@media (max-width: @screen-sm-max) {
			padding-left: 46px;
		}
		a.modify {
			position: absolute;
			right: 20px;
		}
	}
	#welcomeUser, .boxEditUser {
		margin: 0 30px 0 0;
		@media (max-width: @screen-sm-max) {
			margin: 0 30px;
		}
	}
	.boxEditUser {
		@media (max-width: @screen-xs-max) {
			margin: 0 30px 20px 30px !important;
		}
		@media (max-width: @screen-md-max) {
			margin-bottom:20px !important;
		}
	}
	.padded {
		padding: 0 20px;
		@media (max-width: @screen-xs-max) {
			padding: 0;
		}
	}
	.user-data {
		line-height: 3rem;
		margin-bottom: 2rem;
		strong {
			margin-right: 1rem;
		}
		.row {
			margin-bottom: 1rem;
		}
		.sceltaGenere {
			input {
				margin-right: 1.5rem;
			}
		}
		.confirmButton {
			margin-top: 1rem;
			margin-left: 2rem;
		}
	}
	.profileImg {
		width: 100%;
		border: 2px solid @loe-gray;
	}
	.menuUser {
		background-color: @loe-gray-light;
		text-align: center;
		border-bottom: 2px solid @loe-gray;
		margin: 0 30px;
		li {
			a.voceMenu {
				padding: 30px;
				display: block;
				&.active, &:hover {
					background-color: @loe-gray-lighter;
				}
				&.active {
					font-weight: bold;
				}
			}
			.toggleMenuUser {
				top: 4px;
				right: 35px;
				left: auto;
				&:hover {
					background-color: transparent;
				}
			}
		}
	}
	.form-address {
		input, select {
			margin-bottom: 20px;
		}
		strong {
			display: block;
			margin-bottom: 5px;
		}
	}
	#updIndFattForm {
		height: 0;
		overflow: hidden;
	}
	#updIndSpedForm {
		height: 0;
		overflow: hidden;
	}
	#insIndSpedForm {
		height: 0;
		overflow: hidden;
	}
	.updIndirizzoSped {
		height: 0;
		overflow: hidden;
	}
}

#storicoOrdini {
	thead {
		background-color: @loe-gray-light;
		th {
			padding: 1rem;
		}
	}
	td {
		padding: 0.5rem 1rem;
	}
}

.confermaRegistrazione { color: @loe-green; }
.erroreRegistrazione { color: @loe-red; }