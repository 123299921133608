.shoppingbag {
	.translate (@x:100%, @y:0);
	.transition(all 0.20s ease-in-out);
	.opacity (@opacity: 0);
	background-color: @loe-font-color;/*@loe-gray-darker*/
	height: 100vh;
	z-index: 999;
	padding-top: 50px;
	position: fixed;
	top: 0;
	right: 0;
	padding-bottom: 200px;
	@media (max-width: @screen-xs-max) {
		width: 100%;
	}
	/* 768 - 991 */
	@media (min-width: @screen-sm-min) {
		width: 70%;
	}
	/* 992 - 1199 */
	@media (min-width: @screen-md-min) {
		width: 50%;
	}
	/* 1200 + */
	@media (min-width: @screen-lg-min) {
		width: 30%;
	}
	&.on {
		.translate (@x:0, @y:0);
		.opacity (@opacity: 1);
	}
	.toggle-shoppingbag {
		display: block;
		width: 49.14px;
		height: 49px;
		padding: 14px 3px 0 0;
		color: @loe-gray-light;
		position: absolute;
		top: 20px;
		left: 20px;
		span, span:after, span:before {
			height: 2px;
			background-color: #888;
			transition: all 0.3s;
			backface-visibility: hidden;
		}
		span {
			position: relative;
			display: block;
			width: 90%;
			background-color: transparent;
		}
		span:after, span:before {
			content: "";
			position: absolute;
			left: 0;
			top: -13px;
			width: 120%;
		}
		span:after{
			top: 13px;
			transform: rotate(-45deg) translate(7px, -8px);
		}
		span:before {
			transform: rotate(45deg) translate(11px, 11px);
		}
	}
	span.title {
		position: absolute;
		top: 14px;
		left: 90px;
		color: #fff;
	}
	.prods {
		height: 80%;
		overflow: auto;
		.row.prod {
			color: #fff;
			padding: 20px;
			margin: 0 10px 30px 10px;
			border-bottom: 2px solid #fff;
			div {
				padding: 10px;
				a, a:hover {
					color: #fff;
				}
			}
			.qtaItem {
				display: inline-block;
				margin: 0 10px;
			}
			.deleteItem {
				position: absolute;
				top: 10px;
				right: 30px;
			}
		}
	}
	.summary {
		position: absolute;
		bottom: 0;
		margin: 5%;
		padding: 20px;
		background-color: #fff;
		width: 90%;
	}
	.riga_GST {
		cursor: pointer;
	}
}