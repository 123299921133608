body {
	background-color: #FFFFFF !important;
}
header.cart {
	height: 105px;
	@media (max-width: @screen-xs-max) {
		height: 60px;
		.row {
			padding-top: 7px;
		}
	}
}
.containerCarrello.row {
	margin-top: 105px;
	background-color: #fff;
	position: relative;
	padding: 30px !important;
	@media (max-width: @screen-xs-max) {
		margin-top: 60px;
		padding: 10px !important;
	}
	.steps {
		display: none;
		&.attivo {
			display: block;
		}
	}
	a {
		color: @loe-font-color;
		&:hover, &:active, &:focus {
			color: @loe-font-color;
			text-decoration: none;
		}
	}
	table.cart {
		width: 100%;
		border-collapse: collapse;
		margin: 30px 0;
		border: 0 !important;
		thead {
			th {
				.font--16;
				background-color: @loe-gray-light;
				border-bottom: 2px solid #000;
				padding: 5px;
			}
		}
		tbody {
			td {
				padding: 10px 5px;
				overflow: hidden;
				border-bottom: 1px solid @loe-gray;
				.displayQta.modQta.qtaRiga {
					display: inline-block;
					margin: 0 10px;
				}
				.imgOcchiale {
					@media (max-width: @screen-xs-max) {
						margin: 0 !important;
						max-width: 100% !important;
					}
				}
			}
			tr.articolo td {
				@media (max-width: @screen-xs-max) {
					border-bottom: 1px solid @loe-gray-light;
				}
			}
			tr.visible-xs-block {
				table {
					width: calc(100% + 10px);
					border: none;
					margin: -5px;
					td {
						border-bottom: none;
						padding: 20px 10px;
					}
				}
				@media (max-width: @screen-xs-max) {
					display: table-row !important;
				}
			}
		}
	} 
	.codiceConfermaContainer {
		text-align: left;
		float: left !important;
		margin: 0 !important;
	}
	.txtCodePromo, .dati.information input[name=codicePromozionale], .riassunto.information input[name=codicePromozionale] {
		width: 60% !important;
		display: inline-block;
		height: 36px;
		vertical-align: bottom;
		margin: 0 !important;
		max-width: 60% !important;
	}
	.addPromoCode {
		width: 38% !important;
		display: inline-block !important;
		max-width: 40% !important;
		border-radius: 0 !important;
		border: 2px solid !important;
		text-transform: none !important;
		color: #fff !important;
		background-color: @loe-black !important;
		border-color: @loe-black !important;
		text-decoration: none !important;
		height: 36px !important;
		padding: 0 !important;
		&:hover {
			color: @loe-black !important;
			background-color: @loe-gray-light !important;
			border-color: @loe-black !important;
			text-decoration: none !important;
		}
		&:focus, &.focus,
		&:active:hover, &.active:hover,
		&:active:focus, &.active:focus,
		&:active.focus, &.active.focus {
			color: @loe-font-color !important;
			background-color: #fff !important;
			border-color: @loe-black !important;
			text-decoration: none !important;
		}
	}
	.summary {
		background-color: @loe-gray-light;
		padding: 20px;
		max-width: 400px;
		float: right;
		@media (max-width: @screen-xs-max) {
			max-width: 100%;
		}
		table {
			width: 100%;
			border-collapse: collapse;
			td {
				padding-bottom: 20px;
				white-space: nowrap;
				text-align: left;
				&.text--right {
					text-align: right;
				}
				&.text--center {
					text-align: center;
				}
			}
		}
		#checkoutButton {
			width: 100%;
		}
		.checkoutPaypal {
			cursor: pointer;
		}
	}
	.information h1 {
		border-bottom: 2px solid @loe-gray;
		background-color: @loe-gray-light;
		font-family: @loe-font-family;
		text-align: center;
		text-transform: uppercase;
		color: @loe-gray;
		padding: 10px;
	}
	.confermaOrdineButton, .nextStep {
		border-radius: 0;
		border: 2px solid;
		text-transform: none;
		color: #fff;
		background-color: @loe-black;
		border-color: @loe-black;
	}
	.confermaOrdineButton:hover, .nextStep:hover {
		color: @loe-black;
		background-color: @loe-gray-light;
		border-color: @loe-black;
	}
	.confermaOrdineButton:focus, .confermaOrdineButton.focus,
	.confermaOrdineButton:active:hover,
	.confermaOrdineButton.active:hover,
	.confermaOrdineButton:active:focus,
	.confermaOrdineButton.active:focus,
	.confermaOrdineButton:active.focus,
	.confermaOrdineButton.active.focus {
		color: @loe-font-color !important;
		background-color: #fff !important;
		border-color: @loe-black !important;
	}

	&#empty_cart {
		display: none;
		min-height: calc(100vh - 95px);
	}
}
.backToHome {
	display: none;
}
#loginUtentePopup {
    display: none;
}
#guestBox, #loginBox {
	margin: 0 10%;
}
.information {
	input[name=username], input[name=username]:focus, input[name=password], input[name=password]:focus, input[name=conferma_password], input[name=conferma_password]:focus, input[name=nome_s], input[name=nome_s]:focus, input[name=cognome_s], input[name=cognome_s]:focus, input[name=email], input[name=email]:focus, input[name=telefono], input[name=telefono]:focus, input[name=codice_fiscale], input[name=codice_fiscale]:focus, input[name=indirizzo_s], input[name=indirizzo_s]:focus, input[name=cap_s], input[name=cap_s]:focus, input[name=citta_s], input[name=citta_s]:focus, textarea[name=note], textarea[name=note]:focus, input[name=nomeCarta], input[name=nomeCarta]:focus, input[name=numeroCarta], input[name=numeroCarta]:focus, input[name=emailCarta], input[name=emailCarta]:focus, input[name=cvvCarta], input[name=cvvCarta]:focus {
	    padding-left: 10px !important;
	}
	.dati.information input, .dati.information select, .dati.information textarea, .riassunto.information input, .riassunto.information select, .riassunto.information textarea {
	   padding: 0 10px;
	}
}

.containerCarrello {
	.confirmation {
		background-color: #FFFFFF;
		h1 {
			margin-bottom: 20px;
		}
		.greenText {
			color: @loe-black;
		}
		.orderid {
			background-color: @loe-black;
			color: #FFFFFF;
			margin-top: 10px;
			padding: 10px;
		}
	}
	.accountDetails h2, .accountOk h2, .subscribe h2, .subscribeOk h2 {
		color: @loe-black;
		font-weight: bold;
	}
	.newAccount, newsletter {
		input {
			margin: 10px auto;
		}		
	}
}
