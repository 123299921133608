.info.flex {
	background-color: #FFFFFF;
	width: 100%;
	margin: 0 auto;
	display: none;
	.transition(all 0.5s);
	&.on { display: block; }
	a {
		position: relative;
		overflow: hidden;
		padding: 20px 20px 60px 20px;
		img { max-height: 200px; }
		.datiOcchiale {
			position: absolute;
			bottom: 20px;
			left: 20px;
			width: calc(100% - 40px);
			z-index: 2;
		}
		.borderGrow {
			position: absolute;
			top: -5px;
			left: -5px;
			width: calc(100% - 20px);
			height: calc(100% - 20px);
			-webkit-transition-delay: 0s;
			transition-delay: 0s;
			z-index: 1;
			&:before,
			&:after {
				box-sizing: border-box;
				content: '';
				position: absolute;
				border: 2px solid transparent;
				width: 0;
				height: 0;
			}
			&:before {
				top: 0;
				left: 0;
			}
			&:after {
				bottom: 0;
				right: 0;
			}
		}
		&:hover, &.hover {
			.borderGrow {
				top: 10px;
				left: 10px;
				-webkit-transition-delay: 0.5s;
				transition-delay: 0.5s;
				&:before,
				&:after {
					width: 100%;
					height: 100%;
				}
				&:before {
					border-top-color: @loe-black;
					border-right-color: @loe-black;
					transition: width 0.15s ease-out 0.5s, height 0.15s ease-out 0.65s;
				}
				&:after {
					border-bottom-color: @loe-black;
					border-left-color: @loe-black;
					transition: border-color 0s ease-out 0.8s, width 0.15s ease-out 0.8s, height 0.15s ease-out 0.95s;
				}
			}
		}
	}
}
.home {
	position: relative;
	height: 100vh;
	margin-top: 0;
	&.on {
		.pattern, button.discover, #logo-home {
			display: none;
		}
	}
	.cover {
		object-fit: cover;
		width: 100vw;
		height: 100vh;
	}
	.mainImg {
		width: 100%;
		overflow: hidden;
		display: none;
		height: 100vh;
		&.on {
			display: block;
		}
		.main-carousel {
			height: 100vh;
			position: relative;
			.opacity(0);
			.transition(all 0.50s 0.2s);
			&.on { .opacity(1); }
			.carousel-cell {
				height: 100%;
				width: 100%;
				position: relative;
				background-size: cover;
				background-position: center center;
				overflow: hidden;
				.info.flex {
					position: absolute;
					bottom: -100%;
					left: 0;
					z-index: 7;
					display: flex;
					&.on { bottom: 0; }
				}
			}
			.flickity-enabled{position:relative}
			.flickity-enabled:focus{outline:0}
			.flickity-viewport{overflow:hidden;position:relative;height:100%;}
			.flickity-slider{position:absolute;width:100%;height:100%;}
			.carousel {height: 100vh;}
			.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;tap-highlight-color:transparent;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
			.flickity-enabled.is-draggable .flickity-viewport{cursor:move;cursor:-webkit-grab;cursor:grab}
			.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down{cursor:-webkit-grabbing;cursor:grabbing}
			.flickity-prev-next-button{position:absolute;top:50%;border:none;background:transparent;cursor:pointer;-webkit-transform:translateY(-50%);transform:translateY(-50%);display:none;}
			.flickity-prev-next-button.previous{left:10px}
			.flickity-prev-next-button.previous:after{ content: url('@{loe-img-url}/arrow-sx.png'); }
			.flickity-prev-next-button.next{right:10px}
			.flickity-prev-next-button.next:after{ content: url('@{loe-img-url}/arrow-dx.png'); }
			.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}
			.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}
			.flickity-prev-next-button:disabled{opacity:.3;cursor:auto}
			.flickity-prev-next-button svg{ display: none; }
			.flickity-prev-next-button .arrow{fill:#333}
			.flickity-page-dots{position:absolute;width:100%;bottom:-25px;padding:0;margin:0;list-style:none;text-align:center;line-height:1}
			.flickity-rtl .flickity-page-dots{direction:rtl}
			.flickity-page-dots .dot{display:inline-block;width:10px;height:10px;margin:0 8px;background:#333;border-radius:50%;opacity:.25;cursor:pointer}
			.flickity-page-dots .dot.is-selected{opacity:1}
		}
		.scrollImg {
			position: absolute;
			bottom: 10px;
			left: 50%;
			.translate(@x:0, @y:-50%);
			.opacity(1);
			.transition(all 0.50s);
			&.on {
				.opacity(0);
			}
		}
	}
	.pattern {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url("@{loe-img-url}/pattern.png");
		background-color: fade(@loe-gray-darker, 80%);
		z-index: 998;
		.transition(all 0.50s 0.2s);
		&.disapear {
			top: -100%;
		}
	}
	.pattern-white {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url("@{loe-img-url}/pattern-white.png");
		background-color: fade(#FFFFFF, 20%);
		z-index: 6;
		.opacity(0);
		.transition(all 0.5s);
		&.on { .opacity(0.8); }
		@media (max-width: @screen-xs-max) {
			display: none;
		}
	}
	#logo-home {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 50vw;
		z-index: 999;
		min-width: 300px;
		.translate(@x:-50%, @y:-50%);
		.transition(all 0.50s);
		&.disapear {
			top: -150%;
			// .opacity(0);
			// .transition(all 0.50s);
		}
		&.on {
			display: none;
		}
		img {
			width: 100%;
		}
		.claim {
			display: block;
			width: 95%;
			margin: 35px auto 0 auto;
		}
	}
	button.discover,
	a.goToHome {
		position: absolute;
		bottom: 10%;
		left: 50%;
		padding: 10px 30px;
		text-transform: uppercase;
		color: #fff;
		font-size: 20px;
		background-color: transparent;
		z-index: 999;
		background: none;
		border: 0;
		box-shadow: inset 0 0 0 2px #fff;
		text-align: center;
		white-space: nowrap;
		.translate(@x:-50%, @y:0);
		.transition(all 0.5s);
		&.disapear {
			top: -100vh;
			bottom: auto;
		}
		&::before,
		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			.transition(transform 0.5s);
		}
		&:hover {
			color: @loe-gray;
		}
		&:before, &:after {
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			transform-origin: center;
		}
		&:before {
			border-top: 2px solid @loe-gray;
			border-bottom: 2px solid @loe-gray;
			transform: scale3d(0,1,1); // Shrink only width
		}
		&:after {
			border-left: 2px solid @loe-gray;
			border-right: 2px solid @loe-gray;
			transform: scale3d(1,0,1); // Shrink only height
		}
		&:hover:before,
		&:hover:after {
			transform: scale3d(1,1,1); // Show full-size
			.transition(transform 0.5s);
		}
	}
	a.goToHome {
		color: @loe-gray;
		box-shadow: inset 0 0 0 2px @loe-gray;
		bottom: 20%;
		&:hover {
			color: @loe-gray-light-2;
		}
		&:before {
			border-top: 2px solid @loe-gray-light-2;
			border-bottom: 2px solid @loe-gray-light-2;
		}
		&:after {
			border-left: 2px solid @loe-gray-light-2;
			border-right: 2px solid @loe-gray-light-2;
		}
	}
	#error404, #error500 {
		position: absolute;
		top: 30%;
		left: 25%;
		width: 50%;
		z-index: 7;
		min-width: 300px;
		font-size: 20rem;
		text-align: center;
		.subtitle {
			font-size: 4rem;
		}
	}
	@media (max-width: @screen-sm-max) {
		height: auto;
		min-height: 100vh;
		.mainImg {
			margin-top: 100px;
			height: auto;
			.main-carousel {
				height: auto;
				.carousel { height: 100%; }
				.flickity-slider{position:relative !important; .translate(@x:0, @y:0) !important;}
				.flickity-prev-next-button{position:fixed;top:50vh; z-index: 2;}
				.carousel-cell {
					position: relative !important;
					// background-image: none !important;
					// display: none;
					left: 0% !important;
					&.is-selected {display: block;}
					.info.flex {
						position: relative;
						bottom: auto;
						left: auto;
						z-index: 7;
						&.on { bottom: auto; }
					}
				}
				.flickity-prev-next-button.previous:after{ content: url('@{loe-img-url}/arrow-sx-small.png'); }
				.flickity-prev-next-button.next:after{ content: url('@{loe-img-url}/arrow-dx-small.png'); }
			}
		}
		.info.flex a .borderGrow {
			top: 10px;
			left: 10px;
			&:before,
			&:after {
				width: 100%;
				height: 100%;
			}
			&:before {
				border-top-color: @loe-black;
				border-right-color: @loe-black;
				transition: width 0.15s ease-out 0.5s, height 0.15s ease-out 0.65s;
			}
			&:after {
				border-bottom-color: @loe-black;
				border-left-color: @loe-black;
				transition: border-color 0s ease-out 0.8s, width 0.15s ease-out 0.8s, height 0.15s ease-out 0.95s;
			}
		}
	}
}
.marchiHome {
	padding: 5rem;
	.el-3 {
		@media (max-width: @screen-sm-max) {
			padding-bottom: 5rem;
		}
	}
}
#bolliniHome {
	background-color: @loe-gray-light;
	padding: 4rem 2rem;
	margin: 3rem 0 6rem 0;
	.col-xs-12 {
		div {
			display: inline-block;
			padding: 0rem 5rem;
			max-width: 300px;
			@media (max-width: @screen-sm-max) {
				margin-bottom: 4rem;
			}
			@media (max-width: @screen-md-max) {
				padding: 0rem 2rem;
				max-width: 250px;
			}
			i {
				margin-bottom: 2rem;
			}
		}
	}
}
#txtHome {
	margin: 2rem auto;
	.testoHome {
		max-width: 1000px;
		margin: 2rem auto;
		padding: 0rem 4rem;
		h1 {
			margin-bottom: 4rem;
			text-align: center;
		}
		p {
			line-height: 25px;
		}
	}
}