.dettaglio {
	background-color: @loe-gray-light;
	.top {
		height: calc(100vh - 144px);
		@media (max-width: @screen-xs-max) {
			height: calc(100vh - 100px);
		}
		.intro, .img-big, .img-small {
			background-color: #FFF;/*@loe-gray-light*/
		}
		.images {
			@media (max-width: @screen-xs-max) { height: 75%; }
			@media (min-width: @screen-sm-min) { height: 80%; }
			@media (max-height: 500px) { height: 100%; }
			.intro {
				position: relative;
				height: 13%; 
				@media (max-height: 500px) { height: 15%; }
				&.logo {
					height: 10%;
					@media (max-height: 500px) { height: 15%; }
					a {
						display: block;
						position: absolute;
						top: 50%;
						left: 50%;
						height: 75%;
						.translate (@x:-50%, @y:-35%);
						// @media (max-width: @screen-xs-max) { height: 100%; }
						img {
							max-width: initial;
							height: 100%;
						}
					}
				}
				h1 {
					margin: 0;
					@media (max-width: @screen-xs-max) { .font--20; }
					@media (min-width: @screen-sm-min) { .font--30; }
				}
			}
			.img-big {
				position: relative;
				@media (max-width: @screen-xs-max) { height: 55%; }
				@media (min-width: @screen-sm-min) { height: 63%; }
				img.big {
					max-height: 100%;
				}
				.arrow {
					position: absolute;
					@media (max-width: @screen-xs-max) {
						bottom: 0;
						.translate (@x:0, @y:100%);
						z-index: 7;
						height: 10%;
						img {
							@media (max-width: @screen-xs-max) { max-height: 150%; }
						}
					}
					@media (min-width: @screen-sm-min) {
						top: 50%;
						.translate (@x:0, @y:-50%);
					}
				}
				.sx {
					@media (max-width: @screen-xs-max) { left: 10%; }
					@media (min-width: @screen-sm-min) { left: 1%; }
				}
				.dx {
					@media (max-width: @screen-xs-max) { right: 10%; }
					@media (min-width: @screen-sm-min) { right: 1%; }
				}
			}
			.img-small {
				@media (max-width: @screen-xs-max) { height: 15%; }
				@media (min-width: @screen-sm-min) { height: 15%; }
				.alt-img {
					margin: 0 auto;
					height: 90%;
					@media (max-width: @screen-xs-max) { max-width: 60%; }
					@media (min-width: @screen-sm-min) { max-width: 33%; }
					.flex {
						height: 100%;
						justify-content: center;
					}
					.img {
						position: relative;
						height: 100%;
						margin: 2rem;
						@media (max-width: @screen-xs-max) { margin: 0.5rem 0.5rem 0 0.5rem; }
						@media (min-width: @screen-sm-min) { margin: 0.5rem 1rem 0 1rem; }
						&:hover:after, &.active:after {
							content: "";
							width: 50%;
							position: absolute;
							bottom: 0;
							left: 25%;
							border-bottom: 2px solid @loe-gray;
						}
						img {
							max-height: 100%;
							cursor: pointer;
							display: block;
							margin: 0 auto;
						}
					}
				}
			}
		}
		.add {
			background-color: #fff;
			@media (max-width: @screen-xs-max) { height: 25%; }
			@media (min-width: @screen-sm-min) { height: 20%; }
			@media (max-height: 500px) { height: 50%; }
			.price {
				height: 60%;
				vertical-align: middle;
			}
			.space {
				height: 40%;
				padding-left: 10px;
				&.spaceRight {
					padding-left: 0;
					padding-right: 10px;
					@media (max-width: @screen-xs-max) { padding: 0; }
				}
				@media (max-width: @screen-xs-max) { text-align: center !important; padding: 0; }
				.btn {
					.font--18;
					letter-spacing: 2px;
					height: 80%;
				}
				.btn-group {
					height: 100%;
				}
				.dropdown-menu {
					top: 80%;
				}
			}
			.addToCart {
				min-width: @btn-width-default;
			}
		}
	}
	.det {
		background-color: @loe-gray-light; /*#fff*/
		padding-top: 40px;
		ul {
			text-align: center;
			li {
				display: inline-block;
				padding: 10px 20px;
				position: relative;
				cursor: pointer;
				&:hover:after, &.active:after {
					content: "";
					width: 50%;
					position: absolute;
					bottom: 0;
					left: 25%;
					border-bottom: 2px solid @loe-gray;
					font-weight: bold;
				}
			}
		}
		.desc {
			display: none;
			.transition(all 0.20s ease-in-out);
			margin: 0 auto;
			padding: 30px;
			line-height: 30px;
			&.active {
				display: block;
			}
			&#desc-2 {
				strong {
					display: inline-block;
					min-width: 120px;
				}
			}
			table {
				td {
					padding: 0 10px;
				}
			}
		}
		#txtPackaging {
			p {
				font-size: 11px;
				line-height: 18px;
				text-align: center;
				@media (max-width: @screen-xs-max) {
					padding: 0 30px;
				}
			}
		}
	}
	.share {
		background-color: #fff;
		position: relative;
		overflow: hidden;
		height: 162px;
		div {
			height: calc(100% - 5px);
			padding: 60px 0;
			// .box-shadow (0px 0px 5px @loe-gray-light-2);
			border-bottom: 2px solid @loe-gray-light;
		}
		a {
			margin: 0 30px;
			&:hover {
				color: @loe-gray-light-2;
			}
		}
	}
	.crossselling {
		background-color: #fff;
		padding: 60px 0;
		.flex {
			margin: 0 auto;
			a {
				position: relative;
				overflow: hidden;
				padding: 15px;
				.borderGrow {
					position: absolute;
					top: -5px;
					left: -5px;
					width: calc(100% - 20px);
					height: calc(100% - 20px);
					-webkit-transition-delay: 0s;
					transition-delay: 0s;
					z-index: 1;
					&:before,
					&:after {
						box-sizing: border-box;
						content: '';
						position: absolute;
						border: 2px solid transparent;
						width: 0;
						height: 0;
						-webkit-transition-delay: 0s;
						transition-delay: 0s;
					}
					&:before {
						top: 0;
						left: 0;
					}
					&:after {
						bottom: 0;
						right: 0;
					}
				}
				&:hover, &.hover {
					.borderGrow {
						top: 10px;
						left: 10px;
						-webkit-transition-delay: 0.5s;
						transition-delay: 0.5s;
						&:before,
						&:after {
							width: 100%;
							height: 100%;
						}
						&:before {
							border-top-color: @loe-black;
							border-right-color: @loe-black;
							transition: width 0.15s ease-out 0.5s, height 0.15s ease-out 0.65s;
						}
						&:after {
							border-bottom-color: @loe-black;
							border-left-color: @loe-black;
							transition: border-color 0s ease-out 0.8s, width 0.15s ease-out 0.8s, height 0.15s ease-out 0.95s;
						}
					}
				}
			}
		}
	}
}