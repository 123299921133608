//
// Checkboxes
// --------------------------------------------------

@font-family-icon: 'FontAwesome';
@fa-var-check: "\f00c";
@check-icon: @fa-var-check;

.checkbox-variant(@parent, @color) {
	.@{parent} input[type="checkbox"]:checked + label:not(.error), .@{parent} input[type="radio"]:checked + label:not(.error) {
		&::before {
			background-color: @color;
			border-color: @color;
		}
		&::after {
			color: #fff;
		}
	}
}

.checkbox-variant-indeterminate(@parent, @color) {
	.@{parent} input[type="checkbox"]:indeterminate + label:not(.error), .@{parent} input[type="radio"]:indeterminate + label:not(.error) {
		&::before {
			background-color: @color;
			border-color: @color;
		}
		&::after {
			background-color: #fff;
		}
	}
}

.checkbox {
	padding-left: 20px;
	label:not(.error) {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		padding-left: 5px;
		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			width: 17px;
			height: 17px;
			left: 0;
			margin-left: -20px;
			border: 1px solid @input-border;
			border-radius: 3px;
			background-color: #fff;
			.transition(~"border 0.15s ease-in-out, color 0.15s ease-in-out");
		}
		&::after {
			display: inline-block;
			position: absolute;
			width: 16px;
			height: 16px;
			left: 0;
			top: 0;
			margin-left: -20px;
			padding-left: 3px;
			padding-top: 1px;
			font-size: 11px;
			color: @input-color;
		}
	}
	input[type="checkbox"], input[type="radio"] {
		opacity: 0;
		z-index: 1;
		&:focus + label:not(.error)::before {
			.tab-focus();
		}
		&:checked + label:not(.error)::after {
			font-family: @font-family-icon;
			content: @check-icon;
		}
		&:indeterminate + label:not(.error)::after {
			display: block;
			content: " ";
			width: 10px;
			height: 3px;
			background-color: #555555;
			border-radius: 2px;
			margin-left: -16.5px;
			margin-top: 7px;
		}
		&:disabled + label:not(.error) {
			opacity: 0.65;
			&::before {
				background-color: @input-bg-disabled;
				cursor: not-allowed;
			}
		}
	}
	&.checkbox-circle label:not(.error)::before {
		border-radius: 50%;
	}
	&.checkbox-inline {
		margin-top: 0;
	}
}

.checkbox-variant(checkbox-primary, @brand-primary);
.checkbox-variant(checkbox-danger, @brand-danger);
.checkbox-variant(checkbox-info, @brand-info);
.checkbox-variant(checkbox-warning, @brand-warning);
.checkbox-variant(checkbox-success, @brand-success);

.checkbox-variant-indeterminate(checkbox-primary, @brand-primary);
.checkbox-variant-indeterminate(checkbox-danger, @brand-danger);
.checkbox-variant-indeterminate(checkbox-info, @brand-info);
.checkbox-variant-indeterminate(checkbox-warning, @brand-warning);
.checkbox-variant-indeterminate(checkbox-success, @brand-success);

//
// Radios
// --------------------------------------------------

.radio-variant(@parent, @color) {
	.@{parent} input[type="radio"] {
		& + label:not(.error) {
			&::after {
				background-color: @color;
			}
		}
		&:checked + label:not(.error) {
			&::before {
				border-color: @color;
			}
			&::after {
				background-color: @color;
			}
		}
	}
}

.radio {
	padding-left: 20px;
	label:not(.error) {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		padding-left: 5px;
		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			width: 17px;
			height: 17px;
			left: 0;
			margin-left: -20px;
			border: 1px solid @input-border;
			border-radius: 50%;
			background-color: #fff;
			.transition(border 0.15s ease-in-out);
		}
		&::after {
			display: inline-block;
			position: absolute;
			content: " ";
			width: 11px;
			height: 11px;
			left: 3px;
			top: 3px;
			margin-left: -20px;
			border-radius: 50%;
			background-color: @input-color;
			.scale(0, 0);
			.transition-transform(.1s cubic-bezier(.8,-0.33,.2,1.33));
			//curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
		}
	}
	input[type="radio"] {
		opacity: 0;
		z-index: 1;
		&:focus + label:not(.error)::before {
			.tab-focus();
		}
		&:checked + label:not(.error)::after {
			.scale(1, 1);
		}
		&:disabled + label:not(.error) {
			opacity: 0.65;
			&::before {
				cursor: not-allowed;
			}
		}
	}
	&.radio-inline {
		margin-top: 0;
	}
}

.radio-variant(radio-primary, @brand-primary);
.radio-variant(radio-danger, @brand-danger);
.radio-variant(radio-info, @brand-info);
.radio-variant(radio-warning, @brand-warning);
.radio-variant(radio-success, @brand-success);

input[type="checkbox"], input[type="radio"] {
	&.styled:checked + label:not(.error):after {
		font-family: @font-family-icon;
		content: @check-icon;
	}
	& .styled:checked + label:not(.error) {
		&::before {
			color: #fff;
		}
		&::after {
			color: #fff;
		}
	}
}