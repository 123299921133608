footer {
	text-align: center;
	background-color: @loe-gray;
	color: #fff;
	padding: 50px 0 50px 0;
	display: none;
	position: relative;
	z-index: 3;
	&.on {
		display: block;
	}
	.row {
		background-color: @loe-gray;
	}
	a {
		color: #fff !important;
		margin: 0 5px !important;
		&:hover, &:active, &:focus {
			text-decoration: none;
		}
		&.social {
			display: inline-block;
			margin: 30px 30px 0 30px !important;
		}
		@media (max-width: @screen-xs-max) {
			padding: 10px !important;
			display: inline-block;
		}
	}
	#emailSubscriptionFooter {
		max-width: 400px;
		background-color: @loe-black;
		color: #FFF;
		padding: 14px 10px;
		height: auto;
		display: inline-block;
		margin-bottom: 0;
		@media (max-width: @screen-xs-max) {
			margin: 10px;
			max-width: calc(100% - 20px);
		}
	}
	.newsletterSubscription {
		display: inline-block;
		margin: 0;
		// max-width: 175px;
		&:hover {
			border: 2px solid @loe-gray-light !important;
		}
		@media (max-width: @screen-xs-max) {
			display: block;
			margin: 0 auto;
		}
	}
	#newsletterSubscriptionTxtFooter {
		padding-bottom: 1rem;
	    font-style: italic;
	    color: #127112;
	}
	#newsletterSubscriptionTxtFooterError {
		padding-bottom: 1rem;
	    font-style: italic;
	    color: #9e0a0a; 
	}
	.showImpostaStato {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}
#setCountryCurrencyLang {
	color: @loe-gray;
	select {
		width: 100%;
		margin-bottom: 5px;
	}
	label {
		margin-bottom: 0;
	}
	.row {
		background-color: #FFF;
	}
	#setContainer {
		label {
			margin-top: 1rem;
		}
		.row {
			margin-bottom: 1rem;
		}	
	}	
}
#cookiesBar {
	width: 100%;
	background-color: @loe-gray-light;
	color: #000000;
	text-align: center;
	height: 25px;
	font-size: 12px;
	padding-top: 5px;
	z-index: 100;
	#acceptCookies {
		font-size: 14px;
		display: inline-block;
		text-align: center;
		margin-right: 8px;
		cursor: pointer;
	}
	a {
		cursor: pointer;
		text-decoration: underline;
	}
	div {
		margin: auto;
	}
	@media (max-width: @screen-xs-max) {
		height: auto;
		#acceptCookies {
			margin: 0 auto 10px auto;
			border: 1px solid @loe-black;
			width: 30px;
			height: 30px;
			line-height: 28px;
			vertical-align: middle;
			display: block;
		}
	}
}
#paymentFooter {
	span {
		margin-right: 2rem;
	}
	i {
		margin-right: 1rem;
	}
}