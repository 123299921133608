.menu {
	.translate (@x:-100%, @y:0);
	.transition(all 0.20s ease-in-out);
	.opacity (@opacity: 0);
	background-color: @loe-gray-light /*@loe-font-color*/;
	height: 100vh;
	z-index: 999;
	padding-top: 60px;
	position: fixed;
	top: 0;
	overflow: auto;
	@media (max-width: @screen-xs-max) {
		width: 100%;
		max-width: 400px;
	}
	/* 768 - 991 */
	@media (min-width: @screen-sm-min) {
		width: 70%;
		max-width: 400px;
	}
	/* 992 - 1199 */
	@media (min-width: @screen-md-min) {
		width: 50%;
		max-width: 400px;
	}
	/* 1200 + */
	@media (min-width: @screen-lg-min) {
		width: 30%;
		max-width: 400px;
	}
	.toggleMenu {
		display: block;
		width: 49.14px;
		height: 49px;
		padding: 14px 3px 0 0;
		color: @loe-gray-light;
		position: absolute;
		top: 20px;
		right: 20px;
		span, span:after, span:before {
			height: 2px;
			background-color: #888;
			transition: all 0.3s;
			backface-visibility: hidden;
		}
		span {
			position: relative;
			display: block;
			width: 50%;
		}
		span:after, span:before {
			content: "";
			position: absolute;
			left: 100%;
			top: -13px;
			width: 90%;
		}
		span:after{
			top: 13px;
		}

		&.on span {
			background-color: transparent;
		}
		&.on span:before {
			transform: rotate(45deg) translate(11px, 11px);
		}
		&.on span:after {
			transform: rotate(-45deg) translate(7px, -8px);
		}
	}
	.menu-item {
		padding: 10px 0 10px 40px;
		margin: 0 0 5px 0;
		a, a.visited {
			color: @loe-font-color /*@loe-gray-light*/;
			&:hover, &:active {
				color: @loe-gray-darker /*@loe-gray-lighter*/;
				text-decoration: none;
			}
		}
	}
	&.on {
		.translate (@x:0, @y:0);
		.opacity (@opacity: 1);
	}
	.toggle-brand-list {
		color: #fff;
		display: block;
		width: 100%;
		position: relative;
		margin-top: 10px;
		i {
			position: absolute;
			right: 20px;
			top: 50%;
			.translate (@x:0, @y:-50%);
		}
	}
	.brand-list {
		@media (max-width: @screen-xs-max) {
			// display: none;
		}
		@media (min-width: @screen-sm-min) {
			// display: block;
		}
	}
	.myloe {
		color: #fff;
		padding-bottom: 50px;
		.close-myloe-box {
			display: none;
		}
		a {
			position: relative;
			color: #fff;
			display: block;
			img {
				position: absolute;
				right: 20px;
				top: 50%;
				.translate (@x:0, @y:-50%);
			}
		}
		.myloe-form {
			display: none;
			padding: 0 20px;
		}
		input[type=text], input[type=password], input[type=email] {
			margin: 0 0 10px 0;
			padding: 5px 10px;
			border: 1px solid @loe-gray-light;
			display: inline;
			vertical-align: bottom;
			width: 100%;
			height: 36px;
			color: @loe-black;
		}
		input[type=checkbox], input[type=radio] {
			&:after {
				background: @loe-gray-darker;
				cursor: pointer;
			}
		}
	}
}
#loginMessageError, .passwordResetResult, .msgPrivacy, .msgPassword {
	color: @loe-red;
	padding: 10px 0;
}
