@import "variables";

// Mixins
.cursor-disabled() {
	cursor: not-allowed;
}

// Rules
select.bs-select-hidden,
select.selectpicker {
	display: none !important;
}

.bootstrap-select {
	width: 220px \0; /*IE9 and below*/

	// The selectpicker button
	> .dropdown-toggle {
	width: 100%;
	padding-right: 25px;
	z-index: 1;
	}

	> select {
	position: absolute !important;
	bottom: 0;
	left: 50%;
	display: block !important;
	width: 0.5px !important;
	height: 100% !important;
	padding: 0 !important;
	opacity: 0 !important;
	border: none;

	&.mobile-device {
		top: 0;
		left: 0;
		display: block !important;
		width: 100% !important;
		z-index: 2;
	}
	}

	// Error display
	.has-error & .dropdown-toggle,
	.error & .dropdown-toggle {
	border-color: @color-red-error;
	}

	&.fit-width {
	width: auto !important;
	}

	&:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
	width: @btn-width-default;
	}

	.dropdown-toggle:focus {
	// outline: thin dotted #333333 !important;
	// outline: 5px auto -webkit-focus-ring-color !important;
	// outline-offset: -2px;
	}
}

.bootstrap-select.form-control {
	margin-bottom: 0;
	padding: 0;
	border: none;

	&:not([class*="col-"]) {
	width: 100%;
	}

	&.input-group-btn {
	z-index: auto;
	}
}

// The selectpicker components
.bootstrap-select.btn-group {
	&:not(.input-group-btn),
	&[class*="col-"] {
	float: none;
	display: inline-block;
	margin-left: 0;
	}

	// Forces the pull to the right, if necessary
	&,
	&[class*="col-"],
	.row &[class*="col-"] {
	&.dropdown-menu-right {
		float: right;
	}
	}

	.form-inline &,
	.form-horizontal &,
	.form-group & {
	margin-bottom: 0;
	}

	.form-group-lg &.form-control,
	.form-group-sm &.form-control {
	padding: 0;
	}

	// Set the width of the live search (and any other form control within an inline form)
	// see https://github.com/silviomoreto/bootstrap-select/issues/685
	.form-inline & .form-control {
	width: 100%;
	}

	&.disabled,
	> .disabled {
	.cursor-disabled();

	&:focus {
		outline: none !important;
	}
	}

	&.bs-container {
	position: absolute;
	
	.dropdown-menu {
		z-index: @zindex-select-dropdown;
	}
	}

	// The selectpicker button
	.dropdown-toggle {
	.filter-option {
		display: inline-block;
		overflow: hidden;
		width: 100%;
		text-align: left;
	}

	.caret {
		position: absolute;
		top: 50%;
		right: 12px;
		margin-top: -2px;
		vertical-align: middle;
	}
	}

	&[class*="col-"] .dropdown-toggle {
	width: 100%;
	}

	// The selectpicker dropdown
	.dropdown-menu {
	min-width: 100%;
	box-sizing: border-box;

	&.inner {
		position: static;
		float: none;
		border: 0;
		padding: 0;
		margin: 0;
		border-radius: 0;
		box-shadow: none;
	}

	li {
		position: relative;

		&.active small {
		color: #fff;
		}

		&.disabled a {
		.cursor-disabled();
		}

		a {
		cursor: pointer;
		-webkit-user-select: none;
			 -moz-user-select: none;
			-ms-user-select: none;
				user-select: none;

		&.opt {
			position: relative;
			padding-left: 2.25em;
		}

		span.check-mark {
			display: none;
		}

		span.text {
			display: inline-block;
		}
		}

		small {
		padding-left: 0.5em;
		}
	}

	.notify {
		position: absolute;
		bottom: 5px;
		width: 96%;
		margin: 0 2%;
		min-height: 26px;
		padding: 3px 5px;
		background: rgb(245, 245, 245);
		border: 1px solid rgb(227, 227, 227);
		box-shadow: inset 0 1px 1px fade(rgb(0, 0, 0), 5%);
		pointer-events: none;
		opacity: 0.9;
		box-sizing: border-box;
	}
	}

	.no-results {
	padding: 3px;
	background: #f5f5f5;
	margin: 0 5px;
	white-space: nowrap;
	}

	&.fit-width .dropdown-toggle {
	.filter-option {
		position: static;
	}

	.caret {
		position: static;
		top: auto;
		margin-top: -1px;
	}
	}

	&.show-tick .dropdown-menu li {
	&.selected a span.check-mark {
		position: absolute;
		display: inline-block;
		right: 15px;
		margin-top: 5px;
	}

	a span.text {
		margin-right: 34px;
	}
	}
}

.bootstrap-select.show-menu-arrow {
	&.open > .dropdown-toggle {
	z-index: (@zindex-select-dropdown + 1);
	}

	.dropdown-toggle {
	&:before {
		content: '';
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-bottom: 7px solid @color-grey-arrow;
		position: absolute;
		bottom: -4px;
		left: 9px;
		display: none;
	}

	&:after {
		content: '';
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid white;
		position: absolute;
		bottom: -4px;
		left: 10px;
		display: none;
	}
	}

	&.dropup .dropdown-toggle {
	&:before {
		bottom: auto;
		top: -3px;
		border-top: 7px solid @color-grey-arrow;
		border-bottom: 0;
	}

	&:after {
		bottom: auto;
		top: -3px;
		border-top: 6px solid white;
		border-bottom: 0;
	}
	}

	&.pull-right .dropdown-toggle {
	&:before {
		right: 12px;
		left: auto;
	}

	&:after {
		right: 13px;
		left: auto;
	}
	}

	&.open > .dropdown-toggle {
	&:before,
	&:after {
		display: block;
	}
	}
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
	padding: 4px 8px;
}

.bs-actionsbox {
	width: 100%;
	box-sizing: border-box;

	& .btn-group button {
	width: 50%;
	}
}

.bs-donebutton {
	float: left;
	width: 100%;
	box-sizing: border-box;

	& .btn-group button {
	width: 100%;
	}
}

.bs-searchbox {
	& + .bs-actionsbox {
	padding: 0 8px 4px;
	}

	& .form-control {
	margin-bottom: 0;
	width: 100%;
	float: none;
	}
}
