.catalogo {
	.intro {
		.anim {
			.drop-shadow-color (@x: 0, @y: 5px, @blur: 10px, @spread: -5px, @color: @loe-gray-light-2);
			position: absolute;
			top: 0;
			left: -10px;
			bottom: 0;
			width: 200%;
			height: 100%;
			z-index: 7;
			cursor: pointer;
			@media (max-width: @screen-xs-max) {
				cursor: default;
			}
		}
		.logo {
			position: absolute;
			top: 50%;
			left: 30px;
			max-height: 80%;
			z-index: 7;
			.translate (@x:0, @y:-50%);
			.transition(all 0.20s);
			.opacity(1);
			.transition-delay(0.5s);
			&.on {
				.opacity(0);
				.transition-delay(0s);
			}
		}
		.toggleInfo {
			position: absolute;
			bottom: 0;
			left: 45%;
			width: 10%;
			height: 23px;
			text-align: center;
			color: #FFFFFF;
			.font--20;
			.transition(all 0.20s);
			.transition-delay(0.5s);
			@media (max-width: @screen-xs-max) {
				display: none;
			}
			&.on {
				color: @loe-black;
				.arrow-up {
					border-bottom: 25px solid #ffffff;
				}
			}
			i {
				z-index: 2;
				position: relative;
				.font--14;
			}
			.arrow-up {
				width: 0; 
				height: 0; 
				border-left: 25px solid transparent;
				border-right: 25px solid transparent;
				border-bottom: 25px solid @loe-black;
				position: absolute;
				bottom: 0;
				left: calc(50% - 25px);
				z-index: 1;
			}
		}
	}
	.rxSection {
		background-color: @loe-black;
		padding: 10px;
		text-align: center;
		z-index: 7;
		.btn {
			border-color: @loe-gray-light;
			text-transform: inherit;
			&.hover {
				color: @loe-black;
				background-color: @loe-gray-light;
			}
			@media (max-width: @screen-xs-max) {
				margin-bottom: 5px;
				width: 90%;
			}
		}
	}
	.products {
		background-color: #fff;
		padding: 0 30px;
		@media (max-width: @screen-sm-max) {
			margin-top: 20px;
		}
		.introTesto {
			background-color: @loe-black;
			position: relative;
			margin-bottom: 30px;
			min-height: 350px;
			@media (max-width: @screen-md-max) {
				min-height: 0px;
			}
			.boxDesigner {
				position: absolute;
				top: 50%;
				left: 5%;
				width: 90%;
				.translate (@x:0, @y:-50%);
				@media (max-width: @screen-md-max) {
					position: relative;
					top: auto;
					left: auto;
					margin: 0 auto;
					.translate (@x:0, @y:0);
				}
			}
			.labelDesigner {
				color: #fff;
				display: block;
				width: 100%;
				padding: 10px 5px;
				position: relative;
				.toggleDesigner {
					position: absolute;
					top: 4px;
					right: 0;
					color: #FFFFFF;
					width: 100%;
					text-align: right;
					padding-right: 10px;
					cursor: pointer;
					.font--26;
				}
			}
			.flex {
				overflow: hidden;
				.transition(all 0.20s);
				@media (max-width: @screen-xs-max) {
					max-height: 0;
				}
				&.on {
					max-height: 999px;
				}
				.el {
					padding: 10px 5px;
					text-align: center;
					cursor: pointer;
					button {
						display: inline-block;
						padding: 10px;
						border: 2px solid #fff;
						text-align: center;
						color: #fff;
						width: 100%;
						height: 100%;
						cursor: pointer;
						text-transform: none;
						background-color: transparent;
						.font--12;
					}
				}
			}
		}
		.product {
			position: relative;
			margin-bottom: 30px;
			overflow: hidden;
			.transition(all 0.20s);
			.opacity (@opacity: 0);
			&.on {
				.opacity (@opacity: 1);
				.transition(initial);
			}
			.img {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 90%;
				-webkit-transition-delay: 0.1s;
				transition-delay: 0.1s;
				.transition(all 0.50s ease-in);
				.translate3d (@x:-50%, @y:-50%, @z:0);
				&.loading {
					max-width: 300px;
				}
			}
			a {
				display: block;
				width: 100%;
				height: 100%;
			}
			.detaildarker {
				background-color: fade(@loe-black, 5%);
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				-webkit-transition-delay: 0.1s;
				transition-delay: 0.1s;
				.transition(all 0.50s ease-in);
				.opacity (@opacity: 0);
				.borderGrow {
					position: absolute;
					top: -5px;
					left: -5px;
					width: calc(100% - 20px);
					height: calc(100% - 20px);
					-webkit-transition-delay: 0s;
					transition-delay: 0s;
					z-index: 1;
					&:before,
					&:after {
						box-sizing: border-box;
						content: '';
						position: absolute;
						border: 2px solid transparent;
						width: 0;
						height: 0;
						-webkit-transition-delay: 0s;
						transition-delay: 0s;
					}
					&:before {
						top: 0;
						left: 0;
					}
					&:after {
						bottom: 0;
						right: 0;
					}
				}
				.detail {
					color: @loe-black;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 2;
					.transition(all 0.30s ease-in 0.1s);
					.desc {
						text-align: center;
						position: absolute;
						width: 90%;
						top: 10%;
						left: 50%;
						.translate (@x:-50%, @y:0);
						padding: 0 10px;
						h3 {
							font-size: calc(16px + 1.5vw);
							margin: 0 0 10px 0;
							@media (max-width: @screen-xs-max) {
								font-size: calc(16px + 1.4vw);
							}
						}
						.color-desc {
							.font--14;
							.playfair;
							@media (max-width: @screen-xs-max) {
								.font--12;
							}
						}
					}
					.priceButton {
						position: absolute;
						bottom: 10%;
						left: 50%;
						.translate (@x:-50%, @y:0);
						// width: 50%;
						text-align: center;
						p.price {
							.font--30;
							@media (max-width: @screen-sm-max) {
								.font--20;
							}
						}
						.btn {
							width: 100%;
						}
					}
				}
			}
			@media (min-width: @screen-sm-min) {
				&:hover, &.hover {
					.img {
						.opacity (@opacity: 0.5);
					}
					.detaildarker {
						.opacity (@opacity: 1);
						.borderGrow {
							top: 10px;
							left: 10px;
							-webkit-transition-delay: 0.5s;
							transition-delay: 0.5s;
							&:before,
							&:after {
								width: 100%;
								height: 100%;
							}
							&:before {
								border-top-color: @loe-black;
								border-right-color: @loe-black;
								transition: width 0.15s ease-out 0.5s, height 0.15s ease-out 0.65s;
							}
							&:after {
								border-bottom-color: @loe-black;
								border-left-color: @loe-black;
								transition: border-color 0s ease-out 0.8s, width 0.15s ease-out 0.8s, height 0.15s ease-out 0.95s;
							}
						}
					}
				}
			}
			@media (max-width: @screen-xs-max) {
				.img { .opacity (@opacity: 1); }
				.detaildarker {
					.opacity (@opacity: 1);
					.borderGrow {
						top: 10px;
						left: 10px;
						-webkit-transition-delay: 0.5s;
						transition-delay: 0.5s;
						&:before,
						&:after {
							width: 100%;
							height: 100%;
						}
						&:before {
							border-top-color: @loe-black;
							border-right-color: @loe-black;
							transition: width 0.15s ease-out 0.5s, height 0.15s ease-out 0.65s;
						}
						&:after {
							border-bottom-color: @loe-black;
							border-left-color: @loe-black;
							transition: border-color 0s ease-out 0.8s, width 0.15s ease-out 0.8s, height 0.15s ease-out 0.95s;
						}
					}
				}
			}
		}
	}
}

.containerIntro {
	position: relative;
	-webkit-perspective: 2000px;
	-moz-perspective: 2000px;
	-o-perspective: 2000px;
	perspective: 2000px;
}

#card {
	width: 100%;
	height: 100%;
	.transition(all 0.50s);
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	transform-style: preserve-3d;
}
#card.flipped {
	-webkit-transform: rotateX( -180deg );
	-moz-transform: rotateX( -180deg );
	-o-transform: rotateX( -180deg );
	transform: rotateX( -180deg );
}
#card figure {
	display: block;
	margin: 0;
	padding: 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	backface-visibility: hidden;
}

#card .front {
	width: 100%;
}
#card .back {
	position: absolute;
	top: 0;
	left: 0;
	background: @loe-black;
	height: 100%;
	width: 100%;
	-webkit-transform: rotateX( -180deg );
	-moz-transform: rotateX( -180deg );
	-o-transform: rotateX( -180deg );
	transform: rotateX( -180deg );
}
#card .back p {
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 20px;
	text-align: justify;
	color: #FFFFFF;
	line-height: 30px;
	max-height: 100%;
	width: 90%;
	max-width: 1000px;
	.font--16;
	.transform(translate(-50%,-50%));
	@media (max-width: @screen-md-max) {
		.font--14;
		line-height: 20px;
	}
	@media (max-width: @screen-sm-max) {
		.font--12;
		line-height: 18px;
	}
}
.containerIntro.trew {
	.back {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background-color: @loe-black;
		transform: scale(0.0001);
		transition: all 0.2s ease-in;
		&.on {
			transform: scale(1);
		}
		p {
			position: absolute;
			top: 50%;
			left: 50%;
			padding: 20px;
			text-align: justify;
			color: #FFFFFF;
			line-height: 30px;
			max-height: 100%;
			width: 90%;
			max-width: 1000px;
			.font--16;
			.transform(translate(-50%,-50%));
			@media (max-width: @screen-md-max) {
				.font--14;
				line-height: 20px;
			}
			@media (max-width: @screen-sm-max) {
				.font--12;
				line-height: 18px;
			}
		}
	}
}