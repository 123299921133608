
header {
	position: fixed;
	background-color: transparent;
	margin-bottom: -5px;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 8;
	height: 144px;
	@media (max-width: @screen-xs-max) {
		height: 82px;
	}
	&.on {
		.row {
			height: calc(100% - 5px);
			position: relative;
			background-color: #fff;
			.box-shadow (0px 0px 5px @loe-gray-light-2);
			#toggleMenuVoce, .toggleMenu, .logo a {
				.opacity(1);
				.transition-delay(0s);
			}
			.topHeaderInfo {
				.opacity(1);
			}	
		}
		.navigation {
			.opacity(1);
		}
	}
	.row {
		height: calc(100% - 5px);
		position: relative;
		background-color: transparent;
		.transition(all 0.50s);
		.topHeaderInfo {
			background-color: @loe-gray-light;
			padding: 10px;
			margin: 0 0 5px 0;
			.opacity(0);
			.font--14;
			position: relative;
			@media (max-width: @screen-sm-max) {
				.font--10;
			}
			@media (max-width: @screen-xs-max) {
				height: 32px;
				margin: 0;
			}
			span { margin: 0px 15px; }
			.close {
				position: absolute;
				right: 20px;
				top: 50%;
				.translate (@x:0, @y:-50%);
				cursor: pointer;
				.font--14;
				color: @loe-black;
			}
		}
		.logo {
			height: 100%;
			max-height: 100px;
			position: relative;
			@media (max-width: @screen-xs-max) {
				height: 41px;
			}
			a {
				display: block;
				margin: 0 auto;
				width: 70%;
				padding: 5px;
				position: absolute;
				top: 50%;
				left: 50%;
				.opacity(0);
				.transition(all 0.50s 0.5s);
				.translate (@x:-50%, @y:-50%);
				&.on {
					.opacity(1);
				}
				img {
					@media (max-width: @screen-xs-max) {
						height: 100%;
					}
				}
				.claim {
					max-width: 270px;
					margin-top: 5px;
				}
				@media (min-width: @screen-md-min) {
					width: 447px;
				}
				@media (max-width: @screen-xs-max) {
					.translate (@x:0, @y:0);
					position: relative;
					width: auto;
					height: 100%;
					top: auto;
					left: auto;
				}
			}
		}
		#toggleMenuVoce {
			position: absolute;
			bottom: 32px;
			left: 30px;
			.opacity(0);
			.transition(all 0.50s);
			@media (max-width: @screen-xs-max) {
				display: none;
			}
		}
		.toggleMenu {
			display: block;
			width: 49.14px;
			height: 49px;
			padding: 14px 3px 0 0;
			position: absolute;
			left: 30px;
			top: 65%;
			color: @loe-gray;
			.opacity(0);
			.translate (@x:0, @y:-50%);
			.transition(all 0.50s);
			@media (max-width: @screen-xs-max) {
				width: 29.14px;
				bottom: 0;
				top: auto;
				.translate (@x:0, @y:0);
				height: 40px;
			}
			span, span:after, span:before {
				height: 2px;
				background-color: @loe-gray;
				transition: all 0.3s;
				backface-visibility: hidden;
			}
			span {
				position: relative;
				display: block;
				width: 90%;
			}
			span:after, span:before {
				content: "";
				position: absolute;
				left: 0;
				top: -13px;
				width: 100%;
				@media (max-width: @screen-xs-max) {
					top: -8px;
				}
			}
			span:after{
				top: 13px;
				@media (max-width: @screen-xs-max) {
					top: 8px;
				}
			}
			&.on span {
				background-color: transparent;
			}
			&.on span:before {
				.transform(rotate(45deg) translate(11px, 11px));
			}
			&.on span:after {
				.transform(rotate(-45deg) translate(7px, -8px));
			}
		}
	}
	.navigation {
		position: absolute;
		bottom: 20%;
		right: 30px;
		// background-color: #fff;
		.translate (@x:0, @y:-50%);
		.opacity(0);
		.transition(all 0.50s);
		@media (max-width: @screen-xs-max) {
			bottom: 16px;
			top: auto;
		}
		ul.basic-nav {
			margin: 0;
			padding: 0;
			list-style-position: outside;
			list-style-type: none;
			color: @loe-gray;
			& > li {
				display: inline-block;
				margin-left: 20px;
				position: relative;
				@media (max-width: @screen-xs-max) {
					margin-left: 10px;
				}
				span {
					display: none;
					@media (min-width: 1260px) {
						display: inline-block;
						padding-left: 5px;
					}
				}
			}
		}
	}
}
.search-box {
	display: none;
	position: fixed;
	top: 50vh;
	z-index: 89;
	background-color: #fff;
	.transition(all 0.20s ease-in-out);
	.box-shadow (0px 0px 20px @loe-gray);
	@media (max-width: @screen-xs-max) {width: 70%; left: 15vw;}
	@media (min-width: @screen-sm-min) {width: 50%; left: 25vw;}
	@media (min-width: @screen-md-min) {width: 30%; left: 35vw;}
	input[type=text] {
		width: calc(100% - 46px);
	}
	button {
		margin: 0;
		padding: 5px 10px;
		background-color: transparent;
		border: 1px solid @loe-gray-light;
		border-left: 0;
		.font--24;
		width: 46px;
	}
	&.on {
		display: block;
	}
}
.myloe-box {
	display: none;
	position: fixed;
	z-index: 89;
	background-color: #fff;
	padding: 30px;
	overflow: auto;
	.transition(all 0.20s ease-in-out);
	.box-shadow (0px 0px 20px @loe-gray);
	@media (min-width: @screen-sm-min) {width: 80vw; height: 80vh; left: 10vw; top: 10vh;}
	@media (min-width: @screen-md-min) {width: 80vw; height: 80vh; left: 10vw; top: 10vh;}
	@media (min-width: @screen-lg-min) {width: 80vw; height: 60vh; left: 50%; top: 10vh; max-width: 1000px; .translate3d (@x:-50%, @y:0, @z:0);}
	.boxAccedi, .boxRegistrati {
		width: 49%;
		@media (max-width: @screen-xs-max) {
			width: 100%;
		}
	}
	.boxRegistrati {
		float: right;
		@media (max-width: @screen-xs-max) {
			float: none;
		}
	}

	.close-myloe-box {
		position: absolute;
		top: 5px;
		right: 5px;
	}
	h4 {
		text-align: center;
		background-color: @loe-gray-light;
		border-bottom: 2px solid @loe-gray;
		padding: 10px 5px;
		text-transform: capitalize;
		.font--16;
	}
	&.on {
		display: block;
	}
}
.language-box {
	position: absolute;
	top: -10000px;
	left: -12px;
	z-index: 999;
	background-color: transparent;
	border: 1px solid #f3f3f3;
	width: auto;
	.transition(all 0.20s ease-in-out);
	ul {
		margin: 0;
		li {
			padding: 10px 10px 10px 27px;
			display: block;
			width: 100%;
			position: relative;
			background-color: #fff;
			&:before {
				content: "\f0ac";
				font-family: FontAwesome;
				display: inline-block;
				width: 10px;
				position: absolute;
				left: 11px;
			}
			&.toggle-language-box {
				margin-bottom: 20px;
				cursor: pointer;
			}
			a {
				display: inline-block;
				width: 100%;
			}
		}
	}
	&.on {
		top: -11px;
	}
}