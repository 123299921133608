*,*:focus,*:active,*:hover { outline: none !important; }
*:focus + label::before { outline: none !important; }

body {
	margin: 0 !important;
	padding: 0 !important;
	font-family: @loe-font-family;
	font-size: @loe-font-size;
	color: @loe-font-color;
	// background-color: #000;
	position: relative;
	overflow-x: hidden;
	line-height: normal;
	&.on {
		height: 100vh;
		overflow: hidden;
	}
}

.font--10 { font-size: 10px; }
.font--11 { font-size: 11px; }
.font--12 { font-size: 12px; }
.font--13 { font-size: 13px; }
.font--14 { font-size: 14px; }
.font--15 { font-size: 15px; }
.font--16 { font-size: 16px; }
.font--17 { font-size: 17px; }
.font--18 { font-size: 18px; }
.font--19 { font-size: 19px; }
.font--20 { font-size: 20px; }
.font--21 { font-size: 21px; }
.font--22 { font-size: 22px; }
.font--23 { font-size: 23px; }
.font--24 { font-size: 24px; }
.font--25 { font-size: 25px; }
.font--26 { font-size: 26px; }
.font--27 { font-size: 27px; }
.font--28 { font-size: 28px; }
.font--29 { font-size: 29px; }
.font--30 { font-size: 30px; }
.font--31 { font-size: 31px; }
.font--32 { font-size: 32px; }
.font--33 { font-size: 33px; }
.font--34 { font-size: 34px; }
.font--35 { font-size: 35px; }
.font--36 { font-size: 36px; }

.line-height--27 { line-height: 27px; }
.line-height--25 { line-height: 25px; }

.playfair {
	font-family: @loe-font-family /*'Playfair Display', serif*/;
}

.padding--0 { padding: 0 !important; }
.padding-top--0 { padding-top: 0 !important; }
.padding-bottom--0 { padding-bottom: 0 !important; }
.padding-left--0 { padding-left: 0 !important; }
.padding-right--0 { padding-right: 0 !important; }
.text--right { text-align: right; }
.text--left { text-align: left; }
.text--center { text-align: center; }
.text--justify { text-align: justify; }
.text--linethrough { text-decoration: line-through; }
.text--underline { 
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}
.text--dotted {
	border-bottom: 1px dotted @loe-gray;
	&:hover {
		border-bottom: none;
	}
}

.inline-block { display: inline-block !important; }

img { max-width: 100%; }

a {
	color: @loe-font-color;
	&:hover, &:active, &:focus {
		color: @loe-font-color;
		text-decoration: none;
	}
}
.white {
	color: #fff;
	&:hover, &:active, &:focus {
		color: #fff;
	}
}
.font--gray { color: @loe-font-color-gray; }
.font--gray-light { color: @loe-font-color-gray-light; }
.font--gray-lighter { color: @loe-font-color-gray-lighter; }
.loe-gray-light {
	color: @loe-gray-light;
	&:hover, &:active, &:focus {
		color: @loe-gray-light;
	}
}
.loe-gray-light-2 {
	color: @loe-gray-light-2;
	&:hover, &:active, &:focus {
		color: @loe-gray-light-2;
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	list-style-position: outside;
}

.vertical--center {
	display: block;
	position: absolute;
	top: 50%;
	width: 100%;
	.translate (@x:0, @y:-50%);
}

.flex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: flex-start;
	&.flex-center {
		justify-content: center;
	}
}
.el-2, .elgap-2, .el-3, .elgap-3, .el-4, .elgap-4, .el-5, .elgap-5, .el-6, .elgap-6, 
.el-7, .elgap-7, .el-8, .elgap-8, .el-9, .elgap-9, .el-10, .elgap-10 { display: block; }
.el-2, .elgap-2 { width: 48%; }
.el-3, .elgap-3 { width: 32%; }
.el-4, .elgap-4 { width: 24%; }
.el-5, .elgap-5 { width: 19%; }
.el-6, .elgap-6 { width: 16%; }
.el-7, .elgap-7 { width: 14%; }
.el-8, .elgap-8 { width: 12%; }
.el-9, .elgap-9 { width: 10%; }
.el-10, .elgap-10 { width: 8%; }
.elgap-2, .elgap-3, .elgap-4, .elgap-5, .elgap-6, .elgap-7, .elgap-8, .elgap-9, .elgap-10 { height: 0; }
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
	.el-md-2, .elgap-md-2 { width: 48%; }
	.el-md-3, .elgap-md-3 { width: 32%; }
	.el-md-4, .elgap-md-4 { width: 24%; }
	.el-md-5, .elgap-md-5 { width: 19%; }
	.el-md-6, .elgap-md-6 { width: 16%; }
	.el-md-7, .elgap-md-7 { width: 14%; }
	.el-md-8, .elgap-md-8 { width: 12%; }
	.el-md-9, .elgap-md-9 { width: 10%; }
	.el-md-10, .elgap-md-10 { width: 8%; }
}
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	.el-sm-1, .elgap-sm-1 { width: 100%; }
	.el-sm-2, .elgap-sm-2 { width: 48%; }
	.el-sm-3, .elgap-sm-3 { width: 32%; }
	.el-sm-4, .elgap-sm-4 { width: 24%; }
	.el-sm-5, .elgap-sm-5 { width: 19%; }
	.el-sm-6, .elgap-sm-6 { width: 16%; }
	.el-sm-7, .elgap-sm-7 { width: 14%; }
	.el-sm-8, .elgap-sm-8 { width: 12%; }
	.el-sm-9, .elgap-sm-9 { width: 10%; }
	.el-sm-10, .elgap-sm-10 { width: 8%; }
}
@media (max-width: @screen-xs-max) {
	.el-xs-1, .elgap-xs-1 { width: 100%; }
	.el-xs-2, .elgap-xs-2 { width: 48%; }
	.el-xs-3, .elgap-xs-3 { width: 32%; }
	.el-xs-4, .elgap-xs-4 { width: 24%; }
	.el-xs-5, .elgap-xs-5 { width: 19%; }
	.el-xs-6, .elgap-xs-6 { width: 16%; }
	.el-xs-7, .elgap-xs-7 { width: 14%; }
	.el-xs-8, .elgap-xs-8 { width: 12%; }
	.el-xs-9, .elgap-xs-9 { width: 10%; }
	.el-xs-10, .elgap-xs-10 { width: 8%; }
}

button, .button {
	text-transform: uppercase;
}

label { cursor: pointer; }

.icon-myloe {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		content: "";
		width: 18px;
		height: 14px;
		display: block;
		background-image: url("@{loe-img-url}/pittogramma.png");
		background-size: cover;
	}
}

span.wishlist {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    &:before {
		content: "\f08a";
	}
	&.active:before, &:hover:before {
		content: "\f004";
	}
}

.max-width {
	max-width: @container-lg;
}

input[type=checkbox], input[type=radio] {
	position: relative;
	&:after {
		content: '';
		display: block;
		width: 14px;
		height: 14px;
		border: 1px solid @loe-gray;
		background: #fff;
		cursor: pointer;
	}
	&:checked:after {
		content: "\f00c";
		font: normal normal normal 20px FontAwesome;
		text-indent: -1px;
		line-height: 8px;
	}
}
input[type=text], input[type=password], input[type=email], textarea {
	margin: 0;
	padding: 5px 10px;
	border: 1px solid @loe-gray-light;
	vertical-align: bottom;
	height: 41px;
	display: inline;
	width: 100%;
}
textarea {
	height: auto;
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label {
	max-width: 100%;
	font-size: 14px;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	padding: 10px 20px;
}
.inputfile:focus + label, .inputfile.has-focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}
.inputfile + label * {
	/* pointer-events: none; */
	/* in case of FastClick lib use */
}
.inputfile + label svg {
	width: 1em;
	height: 1em;
	vertical-align: middle;
	fill: currentColor;
	margin-top: -4px;
}
.inputfile-2 + label {
	color: #ffffff;
	border: 2px solid @loe-gray;
	background-color: @loe-gray;
}
.inputfile-2:focus + label, .inputfile-2.has-focus + label, .inputfile-2 + label:hover {
	color: @loe-gray;
	background-color: @loe-gray-light;
}
select {
	padding: 5px 10px;
	border: 1px solid @loe-gray-light;
	height: 41px;
}
.error {
	.text-danger;
}
.dropdown-toggle {
	background-color: #fff;
	color: @loe-gray;
}
#darker {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #000;
	.opacity (@opacity: 0);
	display: none;
	z-index: 9;
	.transition(all 0.20s ease-in-out);
	&.on {
		.opacity (@opacity: 0.6);
		display: block;
	}
}
#blanker {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	.opacity (@opacity: 0.96);
	z-index: 88;
	display: none;
	background-color: #fff;
	.transition(all 0.20s ease-in-out);
	&.on {
		display: block;
	}
}
#layeraddtocart {
	display: none;
	position: fixed;
	top: calc(50vh - 40px);
	z-index: 89;
	background-color: #fff;
	.transition(all 0.20s ease-in-out);
	.box-shadow (0px 0px 20px @loe-gray);
	@media (max-width: @screen-xs-max) { width: 80%; left: 10vw;}
	@media (min-width: @screen-sm-min) { width: 70%; left: 15vw;}
	@media (min-width: @screen-md-min) { width: 60%; left: 20vw;}
	@media (min-width: @screen-lg-min) { width: 50%; left: 25vw;}
	height: 80px;
	line-height: 80px;
	vertical-align: middle;
	p {
		@media (max-width: @screen-xs-max) { .font--16; }
		@media (min-width: @screen-sm-min) { .font--20; }
		@media (min-width: @screen-md-min) { .font--24; }
		@media (min-width: @screen-lg-min) { .font--28; }
	}
	&.on {
		display: block;
	}
}

/* area controlli 3w */
#area3W { position:absolute; top:0; right:0; background-color:#FFFFA5; border:1px solid #666; padding:0 20px; z-index:3; font-size:12px; min-width:50px; min-height:50px; -webkit-border-bottom-left-radius: 50px; -moz-border-radius-bottomleft: 50px; border-bottom-left-radius: 50px; -webkit-box-shadow: -3px 3px 5px 0px rgba(50, 50, 50, 0.75); -moz-box-shadow:    -3px 3px 5px 0px rgba(50, 50, 50, 0.75); box-shadow:         -3px 3px 5px 0px rgba(50, 50, 50, 0.75); }
#area3W .row { background-color:transparent; }
#area3W a { text-decoration:none; color:#F00; font-weight:bold; }
#area3W h1 { font-size:15px !important; font-weight:bold; margin-bottom:10px !important; }
#area3W strong { font-weight:bold; }
#area3W img.googlesignin { width:200px; }
#chiudiArea3W{ position:absolute; top: 5px; right: 13px; font-size: 24px; text-align:center; }
#areaControlli{ display:none; }
#area3W #areaControlli { padding:10px 0px; }

.navbar { margin-bottom: 0; }
.container, .container-fluid {
	overflow: hidden;
	min-height: 100vh;
}
.row {
	margin: 0;
	max-width: none !important;
}
.containerContent {
	margin-top: 139px;
	@media (max-width: @screen-xs-max) {
		margin-top: 100px;
	}
}
.errorPage {
	min-height: calc(100vh - 139px);
	@media (max-width: @screen-xs-max) {
		min-height: calc(100vh - 100px);
	}
}

.btn {
	border-radius: 0;
	border: 2px solid;
	// text-transform: none;
	text-transform: uppercase;
}
.btn-default {
	color: #fff;
	background-color: @loe-black;
	border-color: @loe-black;
}
.btn-default:hover {
	color: @loe-black;
	background-color: @loe-gray-light;
	border-color: @loe-black;
}
.btn-default:focus, .btn-default.focus,
.btn-default:active:hover,
.btn-default.active:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default:hover,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default.focus {
	color: @loe-font-color !important;
	background-color: #fff !important;
	border-color: @loe-black !important;
}

.btn-default.invert {
	color: @loe-black;
	background-color: @loe-gray-light;
	border-color: @loe-gray-light;
}
.btn-default.invert:hover {
	color: @loe-gray-light;
	background-color: @loe-black;
	border-color: @loe-black;
}
.btn-default.invert:active:hover,
.btn-default.invert.active:hover,
.btn-default.invert:active:focus,
.btn-default.invert.active:focus,
.btn-default.invert:active.focus,
.btn-default.invert.active.focus, {
	color: @loe-black;
	background-color: @loe-gray-light;
	border-color: @loe-black;
}

.btn-lg {
	padding-left: 40px;
	padding-right: 40px;
}

.modal {
	top: 10%;
	right: auto;
	bottom: auto;
	@media (max-width: @screen-xs-max) { width: 90%; left: 5%; }
	@media (min-width: @screen-sm-min) { width: 70%; left: 15%; }
	@media (min-width: @screen-md-min) { width: 50%; left: 25%; max-width: 600px; min-width: 500px; }
	@media (min-width: @screen-lg-min) { width: 40%; left: 50%; max-width: 600px; min-width: 500px; transform: translate(-50%,0); }
}
.modal.large {
	@media (max-width: @screen-xs-max) { width: 90%; left: 50%; transform: translate(-50%,0); }
	@media (min-width: @screen-sm-min) { width: 70%; left: 50%; transform: translate(-50%,0); }
	@media (min-width: @screen-md-min) { width: 60%; left: 50%; max-width: 700px; min-width: 500px; transform: translate(-50%,0); }
	@media (min-width: @screen-lg-min) { width: 60%; left: 50%; max-width: 700px; min-width: 500px; transform: translate(-50%,0); }
}

.close-reveal-modal {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 3rem
}
a.modal__close {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 99999;
	display: block;
}
body.modalOpened .modal {
	-webkit-animation: scale-in-center-o-center 1s cubic-bezier(0.680, 0, 0.265, 1.550) 0.5s both;
	-moz-animation: scale-in-center-o-center 1s cubic-bezier(0.680, 0, 0.265, 1.550) 0.5s both;
	animation: scale-in-center-o-center 1s cubic-bezier(0.680, 0, 0.265, 1.550) 0.5s both;
}
.modal-newsletter-content {
	position: absolute;
	width: 60%;
	top: 50%;
	right: 5%;
	.transform(translate(0,-50%));

	input {
		width:60%;
		padding: 14px 10px;
		height: auto;
		display: inline-block;
		margin-bottom: 0;
		border: 1px solid @loe-font-color;
	}

	.newsletterSubscription {
		width: 36%;
		padding: 10px 2%;
	}

	@media (max-width: @screen-xs-max) {
		position: relative;
		width: 100%;
		right: 0;
		top: 0;
		padding: 10px;
		border: 7px solid @loe-font-color;
		background-color: #ffffff;
		.transform(none);
		.font--26 { font-size: 20px; }
		input, .newsletterSubscription { display: block; width: 100%;}
	}
}


/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}
@-webkit-keyframes scale-in-center-o-center {
	0% {
		-webkit-transform: translate(-50%,0) scale(0);
		transform: translate(-50%,0) scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(-50%,0) scale(1);
		transform: translate(-50%,0) scale(1);
		opacity: 1;
	}
}
@keyframes scale-in-o-center {
	0% {
		-webkit-transform: translate(-50%,0) scale(0);
		transform: translate(-50%,0) scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(-50%,0) scale(1);
		transform: translate(-50%,0) scale(1);
		opacity: 1;
	}
}
@-webkit-keyframes scale-in-center-v-center {
	0% {
		-webkit-transform: translate(0,-50%) scale(0);
		transform: translate(0,-50%) scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(0,-50%) scale(1);
		transform: translate(0,-50%) scale(1);
		opacity: 1;
	}
}
@keyframes scale-in-v-center {
	0% {
		-webkit-transform: translate(0,-50%) scale(0);
		transform: translate(0,-50%) scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(0,-50%) scale(1);
		transform: translate(0,-50%) scale(1);
		opacity: 1;
	}
}
@-webkit-keyframes scale-in-center-vo-center {
	0% {
		-webkit-transform: translate(-50%,-50%) scale(0);
		transform: translate(-50%,-50%) scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(-50%,-50%) scale(1);
		transform: translate(-50%,-50%) scale(1);
		opacity: 1;
	}
}
@keyframes scale-in-vo-center {
	0% {
		-webkit-transform: translate(-50%,-50%) scale(0);
		transform: translate(-50%,-50%) scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(-50%,-50%) scale(1);
		transform: translate(-50%,-50%) scale(1);
		opacity: 1;
	}
}