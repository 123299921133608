.pagineStatiche {
	padding: 0 30%;
	margin-bottom: 3rem;
	@media (max-width: @screen-xs-max) {padding: 0 10px;}
	@media (max-width: @screen-sm-max) {padding: 0 20px;}
	@media (max-width: @screen-md-max) {padding: 0 10%;}
	h1 {
		margin: 5rem 0rem 4rem 0rem;
	}
	p {
		line-height: 2.8rem;
	}
	#confInvioMail {
		color: #008000;
	}
	#msgAlert {
		color: #008000;
		margin-bottom: 3rem;
	}
	a {
		text-decoration: underline;
	}
}
#formContactUs {
	background-color: @loe-gray-light;
	padding: 3rem;
	.row {
		margin-bottom: 1rem;
	}
	label {
		padding-top: 1rem;
	}
	#confInvioMail {
		padding-top: 1.05rem;
		color: #008000;
		font-weight: normal;
	}
}

#dettaglioOrdine {
	#msgStatoOrdine p {
		font-style: italic;
		line-height: 1.8rem;
		margin-top: 5rem;
	}

	#statoOrdine {
		background-color: @loe-gray-light;	
		margin-bottom: 1rem;
		padding: 1rem;
		td {
			padding: 0.5rem;
			&.labelDati {
				padding-right: 1rem;
			}
		}
	}

	#prodottiOrdine {
		h3 {
			margin-bottom: 2rem;
		} 
		table {
			width: 100%;
			thead {
				background-color: @loe-gray-light;
				th {
					padding: 1rem;
				}
			}
			td {
				padding: 0.5rem 0;
			}
			.valueDati {
				/*padding: 1rem 0;*/
			}
			.paddingTop3 {
				padding-top: 3rem;
				vertical-align: top;
			}
		}
	}

	table.tableInfoCliente {
		td {
			padding: 0.5rem 0;
			&.labelDati {
				padding-right: 3rem;
				font-weight: bold;
			}
		}
	}
}

.g-recaptcha > div {
	margin: 0 auto;
}

#formRichiestaReso {
	.row {
		margin-bottom: 1rem;
	}
}

#newsletterUnsubscriptionTxt {
	margin: 2rem 0;
    font-style: italic;
    color: #127112;
}

@media (max-width: @screen-xs-max) {
	#pagineStatiche {
		h1 {
			font-size: 2.5rem;
			margin: 2rem 0 2rem 0;
		}
	}

	#dettaglioOrdine {		
		h2, h3 {
			font-size: 1.8rem;
		}
		#infoFatt {
			margin-top: 2rem;
		}
		#msgStatoOrdine p {
			margin-top: 3rem;
		}
	}	
}

@media (max-width: @screen-sm-max) {
	#pagineStatiche {
		h1 {
			font-size: 2.5rem;
			margin: 2rem 0 2rem 0;
		}
	}

	#dettaglioOrdine {		
		h2, h3 {
			font-size: 1.8rem;
		}
		#msgStatoOrdine p {
			margin-top: 3rem;
		}
	}	
}

@media (max-width: @screen-md-max) {
	#pagineStatiche {
		h1 {
			font-size: 2.5rem;
			margin: 2rem 0 2rem 0;
		}
	}

	#dettaglioOrdine {		
		h2, h3 {
			font-size: 1.8rem;
		}
		#msgStatoOrdine p {
			margin-top: 3rem;
		}
	}	
}
